import React from 'react';
import axios from 'axios';

function useXPayPayorPayee() {
  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [error, setError] = React.useState('');

  const setShowAgain = React.useCallback(({ companyId, isShowMakePayorModal }, onSuccess = () => {}) => {
    setLoading(true);
    setLoaded(false);
    setError('');

    const queryParams = `isNotShowAgain=${isShowMakePayorModal}`;

    axios.put(`/api/xpay/${companyId}/setShowAgain?${queryParams}`)
      .then(({ data }) => {
        setLoaded(true);
        onSuccess(data);
      })
      .catch((e) => {
        setLoaded(false);
        setError(e?.response?.message || 'Internal error, try later.');
      }).finally(() => {
        setLoading(false);
      });
  }, []);

  const sendRequestMakePayments = React.useCallback(({ companyId }, onSuccess = () => {}) => {
    setLoading(true);
    setLoaded(false);
    setError('');

    axios.put(`/api/xpay/${companyId}/sendRequestMakePayments`)
      .then(({ data }) => {
        setLoaded(true);
        onSuccess(data);
      })
      .catch((e) => {
        setLoaded(false);
        setError(e?.response?.message || 'Internal error, try later.');
      }).finally(() => {
        setLoading(false);
      });
  }, []);

  return {
    loading,
    loaded,
    error,
    setShowAgain,
    sendRequestMakePayments,
  };
}

export default useXPayPayorPayee;
