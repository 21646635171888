export const drawerWidth = 254;
export const advancePercentageDefaultValue = 0.8;
export const EIN_LENGTH = 9;

export const companyTypes = {
  GENERAL_CONTRACTOR: 'general_contractor',
  OTHER: 'other',
  STRUXTION: 'struxtion',
  SUB_CONTRACTOR: 'sub_contractor',
  XPAY_GENERAL_CONTRACTOR: 'xpay_general_contractor',
  XPAY_SUB_CONTRACTOR: 'xpay_sub_contractor',
  SUPPLIER: 'supplier',
};

export const program = {
  x_pay: 'x_pay',
  x_pay_plus: 'x_pay_plus',
};

export const bankAccountType = {
  operating: 'operating',
  struxtion: 'struxtion',
  xda: 'xda',
};

export const programTypes = {
  // early_pay: 'x_pay_plus',
  // prompt_pay: 'x_pay',
  early_pay: 'XPay+',
  prompt_pay: 'XPay',
  x_pay: 'XPay',
  x_pay_plus: 'XPay+',
  djc: 'Digital Joint Check',
  all: 'All',
};

export const xPayFundedBy = {
  struxtion: 'Struxtion',
  general_contractor: 'General Contractor',
};

export const xPayProgramTypes = {
  x_pay: 'XPay',
  x_pay_plus: 'XPay+',
};

export const companyTypesFriendlyAliases = {
  general_contractor: 'GC',
  other: 'Other',
  struxtion: 'Admin',
  sub_contractor: 'SC',
};
export const completeCompanyTypesFriendlyAliases = {
  general_contractor: 'General Contractor',
  other: 'Other',
  struxtion: 'Administrator',
  sub_contractor: 'Sub Contractor',
};
export const fundedByTypes = {
  GENERAL_CONTRACTOR: 'general_contractor',
  STRUXTION: 'struxtion',
};
export const companyIncTypes = {
  CORPORATION: 'corporation',
  LLC: 'llc',
  PARTNERSHIP: 'partnership',
  SOLE_PROPRIETORSHIP: 'sole_proprietorship',
};
export const companyIncTypesFriendlyAliases = {
  corporation: 'Corporation',
  llc: 'LLC',
  partnership: 'Partnership',
  sole_proprietorship: 'Sole Proprietorship',
};
export const companyIncTypesSolid = {
  corporation: 'Unlisted Corporation',
  llc: 'Limited Liability Company',
  partnership: 'General Partnership',
  sole_proprietorship: 'Sole Proprietorship',
};
export const solidCompanyIncTypes = {
  corporation: 'unlistedCorporation',
  llc: 'limitedLiabilityCompany',
  partnership: 'generalPartnership',
  sole_proprietorship: 'soleProprietor',
};
export const solidCompanyIncTypesFriendlyAliases = {
  generalPartnership: 'Partnership',
  limitedLiabilityCompany: 'LLC',
  soleProprietor: 'Sole Proprietorship',
  unlistedCorporation: 'Corporation',
};

export const docusignTypes = {
  MECHANICS_LIEN: 'mechanic_lien',
  CLOSING_DOCUMENTS: 'closing_document',
  LIEN_WAIVER: 'lien_waiver',
  XPAY_OFFER: 'xpay_offer',
};

export const docusignName = {
  MECHANICS_LIEN: 'Mechanics Lien',
  CLOSING_DOCUMENTS: 'Closing Document',
};

export const docTypes = {
  ARTICLES_OF_INCORPORATION: 'articles_of_incorporation',
  ARTICLES_OF_ORGANIZATION: 'articles_of_organization',
  BALANCE_SHEET: 'balance_sheet',
  CERTIFICATE_OF_GOOD_STANDING: 'certificate_of_good_standing',
  COMPANY_BYLAWS: 'company_bylaws',
  CONTRACT: 'contract',
  DOING_BUSINESS_AS: 'doing_business_as',
  INCOME_STATEMENT: 'income_statement',
  LOAN_AGREEMENT: 'loan_agreement',
  MECHANIC_LIEN: 'mechanic_lien',
  OPERATING_ACCOUNT: 'operating_account',
  OPERATING_AGREEMENT: 'operating_agreement',
  PAPER_CHECK_BACK: 'paper_check_back',
  PAPER_CHECK_FRONT: 'paper_check_front',
  PARTNERSHIP_AGREEMENT: 'partnership_agreement',
  PAYMENT_APPLICATION: 'payment_application',
  PHOTO_ID: 'photo_id',
  PROJECT_CONTRACT: 'project_contract',
  STATEMENT: 'statement',
  TAX_RETURN: 'tax_return',
  NOTICE: 'notice',
  UNCATEGORIZED: 'uncategorized',
};

export const docTypesFriendlyAliases = {
  articles_of_incorporation: 'Articles of Incorporation',
  articles_of_organization: 'Articles of Organization',
  balance_sheet: 'Balance Sheet',
  certificate_of_good_standing: 'Certificate of Good Standing',
  company_bylaws: 'Company Bylaws',
  contract: 'Project Contract',
  doing_business_as: 'Doing Business As',
  income_statement: 'Income Statement',
  loan_agreement: 'Loan Agreement',
  mechanic_lien: 'Mechanic Lien Waiver',
  closing_document: 'Closing Document',
  operating_account: 'Operating Account',
  operating_agreement: 'Operating Agreement',
  paper_check_back: 'Check Back',
  PAPER_CHECK_BACK: 'Check Back',
  paper_check_front: 'Check Front',
  PAPER_CHECK_FRONT: 'Check Front',
  partnership_agreement: 'Partnership Agreement',
  payment_application: 'Payment Application',
  photo_id: 'Photo ID',
  project_contract: 'Project Contract',
  statement: 'Statement',
  tax_return: 'Tax Return',
  uncategorized: 'None',
  notice: 'Notice',
  company_logo: 'Company Logo',
  xpay_plus_contract: 'XPay+ Contract',
  xpay_plus_offering: 'XPay+ Offering',
  lien_waiver_final: 'Lien Waiver Final',
  lien_waiver_partial: 'Lien Waiver Partial',
};

export const LienWaiverDJCTypes = {
  djc_lien_waiver_final: 'Lien Waiver Final',
  djc_lien_waiver_partial: 'Lien Waiver Partial',
};

export const docTypesFriendlyAliasesArray = [
  { value: 'xpay_plus_offering', label: 'XPay+ offering' },
  { value: 'xpay_plus_contract', label: 'XPay+ contract' },
  { value: 'articles_of_incorporation', label: 'Articles of Incorporation' },
  { value: 'articles_of_organization', label: 'Articles of Organization' },
  { value: 'company_logo', label: 'Company Logo' },
  { value: 'balance_sheet', label: 'Balance Sheet' },
  { value: 'certificate_of_good_standing', label: 'Certificate of Good Standing' },
  { value: 'company_bylaws', label: 'Company Bylaws' },
  { value: 'contract', label: 'Project Contract' },
  { value: 'doing_business_as', label: 'Doing Business As' },
  { value: 'income_statement', label: 'Income Statement' },
  { value: 'loan_agreement', label: 'Loan Agreement' },
  { value: 'lien_waiver_partial', label: 'Lien Waiver Partial' },
  { value: 'lien_waiver_final', label: 'Lien Waiver Final' },
  { value: 'mechanic_lien', label: 'Mechanic Lien Waiver' },
  { value: 'closing_document', label: 'Closing Document' },
  { value: 'operating_agreement', label: 'Operating Agreement' },
  { value: 'partnership_agreement', label: 'Partnership Agreement' },
  { value: 'payment_application', label: 'Payment Application' },
  { value: 'photo_id', label: 'Photo ID' },
  { value: 'project_contract', label: 'Project Contract' },
  { value: 'statement', label: 'Statement' },
  { value: 'tax_return', label: 'Tax Return' },
  { value: 'notice', label: 'Notice' },
].sort((acc, value) => acc.label.localeCompare(value.label));

export const applicationStatuses = {
  COMPLETE: 'Complete',
  IN_PROGRESS: 'In Progress',
  UNDER_REVIEW: 'Under Review',
};

export const taskStatuses = {
  completed: 'Completed',
  not_started: 'Not Started',
  rejected: 'Rejected',
  submitted: 'Submitted',
};

export const registrationStatuses = {
  COMPLETE: 'complete',
  GC_COMPLETE: 'gc_complete',
  REGISTERED: 'registered',
  UPLOADED_DOCS: 'uploaded_docs',
  VERIFIED_COMPANY: 'verified_company',
  VERIFIED_PROJECT: 'verified_project',
  VERIFIED_USER_INFO: 'verified_user_info',
};

export const solidFiStatuses = {
  approved: 'approved',
  declined: 'declined',
  inReview: 'in review',
  notStarted: 'not started',
  submitted: 'submitted',
};

export const registrationStatusesFriendlyAliases = {
  complete: 'Complete',
  registered: 'Registered',
  uploaded_docs: 'Uploaded docs',
  verified_company: 'Verified company',
  verified_project: 'Verified project',
  verified_user_info: 'Verified user',
};

export const paymentInstructionsProgramType = [
  { value: 'x_pay', label: 'XPay' },
  { value: 'x_pay_plus', label: 'XPay+' },
  // { value: 'djc', label: 'Digital Joint Check' },
];

export const states = [
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VA', label: 'Virginia' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' },
];

export const contractorTypes = [
  { name: 'drywall', label: 'Drywall' },
  { name: 'electrical', label: 'Electrical' },
  { name: 'excavation', label: 'Excavation' },
  { name: 'foundations', label: 'Foundations' },
  { name: 'framing', label: 'Framing' },
  { name: 'hvac', label: 'HVAC' },
  { name: 'otherNonTech', label: 'Other Non-Technical' },
  { name: 'otherTech', label: 'Other Technical' },
  { name: 'painting', label: 'Painting' },
  { name: 'plumbing', label: 'Plumbing' },
  { name: 'roofing', label: 'Roofing' },
];

export const incorporationTypes = [
  { value: companyIncTypes.CORPORATION, label: 'Corporation' },
  { value: companyIncTypes.LLC, label: 'LLC' },
  { value: companyIncTypes.PARTNERSHIP, label: 'Partnership' },
  { value: companyIncTypes.SOLE_PROPRIETORSHIP, label: 'Sole Proprietorship' },
];

export const fundedBy = [
  { value: fundedByTypes.GENERAL_CONTRACTOR, label: 'General Contractor' },
  { value: fundedByTypes.STRUXTION, label: 'Struxtion' },
];

export const ProgramType = [
  { value: programTypes.prompt_pay, label: 'Xpay', id: 'x_pay' },
  { value: programTypes.early_pay, label: 'Xpay+', id: 'x_pay_plus' },
  { value: programTypes.x_pay, label: 'Xpay', id: 'x_pay' },
  { value: programTypes.x_pay_plus, label: 'Xpay+', id: 'x_pay_plus' },
];

export const ProgramTypeCopy = [
  { value: programTypes.prompt_pay, label: 'XPay', id: 'x_pay' },
  { value: programTypes.early_pay, label: 'XPay+', id: 'x_pay_plus' },
];

export const docStatuses = {
  ACCEPTED: 'accepted',
  DELETED: 'deleted',
  DISCARDED: 'rejected',
  NEW: 'new',
  NOT_STARTED: 'not_started',
  REVIEWED: 'reviewed',
};

export const loanStatuses = {
  APP_IN_PROGRESS: 'app_in_progress',
  APP_IN_REVIEW: 'app_in_review',
  CLOSED: 'closed',
  QUOTE_GENERATED: 'quote_generated',
  QUOTE_REQUESTED: 'quote_requested',
  SERVICING: 'servicing',
  SIGNING_REQUESTED: 'signing_requested',
};

export const loanStatusesFriendlyAliases = {
  APP_IN_PROGRESS: 'App In Progress',
  APP_IN_REVIEW: 'App In Review',
  CLOSED: 'Closed',
  QUOTE_GENERATED: 'Quote Generated',
  QUOTE_REQUESTED: 'Quote Requested',
  SERVICING: 'Servicing',
  SIGNING_REQUESTED: 'Signing Requested',
};

export const payAppStatuses = {
  ADVANCE_REQUESTED: 'advance_requested',
  ADVANCED: 'advanced',
  AVAILABLE: 'available',
  DISCARDED: 'discarded',
  NEW: 'new',
  PAID: 'paid',
};
export const gcPayAppStatuses = {
  ALREADY_PAID: 'already_paid',
  APPROVED: 'approved',
  NOT_APPROVED: 'not_approved',
  PENDING: 'pending',
  SENT: 'sent',
};
export const events = {
  ON_CANCEL_LINK_TO_DOCS: 'ON_CANCEL_LINK_TO_DOCS',
  ON_CANCEL_PAID_MODAL: 'ON_CANCEL_PAID_MODAL',
  ON_CANCEL_PREVIEW_DOCUMENT: 'ON_CANCEL_PREVIEW_DOCUMENT',
  ON_CANCEL_PULL_FROM_SOA: 'ON_CANCEL_PULL_FROM_SOA',
  ON_CANCEL_REQUEST_GC_VERIFICATION: 'ON_CANCEL_REQUEST_GC_VERIFICATION',
  ON_CHECK_ML: 'ON_CHECK_ML',
  ON_CREATE_NEW_SC_PROJECT: 'ON_CREATE_NEW_SC_PROJECT',
  ON_CREATE_NEW_USER: 'ON_CREATE_NEW_USER',
  ON_CREATE_QUOTE: 'ON_CREATE_QUOTE',
  ON_DELETE_PAYMENT: 'ON_DELETE_PAYMENT',
  ON_DENY_ADVANCE_REQUEST: 'ON_DENY_ADVANCE_REQUEST',
  ON_DISCARD_PAY_APP: 'ON_DISCARD_PAY_APP',
  ON_DOC_EDITED: 'ON_DOC_EDITED',
  ON_DOC_REVIEW: 'ON_DOC_REVIEW',
  ON_DOCS_UPLOADED: 'ON_DOCS_UPLOADED',
  ON_DOWNLOAD_PAY_APP: 'ON_DOWNLOAD_PAY_APP',
  ON_EDIT_PAY_APP: 'ON_EDIT_PAY_APP',
  ON_GENERATE_ML: 'ON_GENERATE_ML',
  ON_MARK_ADVANCED: 'ON_MARK_ADVANCED',
  ON_MARK_AS_PAID: 'ON_MARK_AS_PAID',
  ON_NEW_ADJUSTMENT: 'ON_NEW_ADJUSTMENT',
  ON_NEW_ADVANCE_REQUEST: 'ON_NEW_REQUEST_ADVANCE',
  ON_NEW_ADVANCE: 'ON_NEW_ADVANCE',
  ON_NEW_PAYMENT: 'ON_NEW_PAYMENT',
  ON_PREVIEW_DOCUMENT: 'ON_PREVIEW_DOCUMENT',
  ON_PROCESS_PAY_APP: 'ON_PROCESS_PAY_APP',
  ON_PULL_FROM_SOA: 'ON_PULL_FROM_SOA',
  ON_RECOVER_PAY_APP: 'ON_RECOVER_PAY_APP',
  ON_REQUEST_GC_VERIFICATION: 'ON_REQUEST_GC_VERIFICATION',
  ON_REVIEW_PAY_APP: 'ON_REVIEW_PAY_APP',
  ON_SET_LINK_TO_DOCS: 'ON_SET_LINK_TO_DOCS',
  ON_STATEMENTS_UPLOADED: 'ON_STATEMENTS_UPLOADED',
  ON_UPDATE_PAY_APP_GCSTATUS: 'ON_UPDATE_PAY_APP_GCSTATUS',
  ON_VIEW_DETAILS: 'ON_VIEW_DETAILS',
  REFRESH_LOAN: 'REFRESH_LOAN',
  MAKE_A_PAYMENT: 'MAKE_A_PAYMENT',
};

export const filterKeys = {
  COMPANY_NAME: 'companyName',
  LOAN_STATUS: 'loanStatus',
  NAME: 'name',
  PROJECT_NAME: 'projectName',
};

export const bankAccountStatusFriendlyAliases = {
  active_no_plaid: 'ACTIVE NO PLAID',
  active: 'ACTIVE',
  other: '--',
  pending_automatic_verification: 'PENDING AUTOMATIC VERIFICATION',
  pending_manual_verification: 'PENDING MANUAL VERIFICATION',
};

export const transferNotificationTypes = {
  GBAtoSFA: 'IntraGBA-to-SFA',
  GBAtoSOA: 'ACHPush-GBA-to-SOA',
  PullSRAtoGOA: 'ACHPull-GOA-to-SRA',
  SFAtoGBA: 'IntraSFA-to-GBA',
  SOAtoSFA: 'ACHPull-SOA-to-SFA',
  SOAtoSRA: 'ACHPull-SOA-to-SRA',
  SRAtoGOA: 'ACHPush-SRA-to-GOA',
  SRAtoSFA: 'IntraSRA-to-SFA',
  SRAtoFifthThird: 'ACHPush-SRA-to-STX',
  GBAtoGOA: 'ACHPush-GBA-to-GOA',
  GOAtoGBA: 'ACHPull-GOA-to-GBA',
  GBAtoSRA: 'IntraGBA-to-SRA',
};

export const subscriptionStatus = {
  active: 'Active',
  inactive: 'Inactive',
};

export const transactionTypesAliases = {
  advance: 'Advance',
  advance_request: 'Advance Request',
  payment: 'Payment',
  adjustment: 'Adjustment',
  subscription: 'Subscription',
  single_payment: 'Single Payment',
};

export const BULK_TITLES = {
  sub_contractor: 'COMPANIES',
  projects: 'PROJECT',
  payments: 'PAYMENT',
};

export const BULK_TYPES = {
  SUB_CONTRACTOR: 'sub_contractor',
  PROJECT: 'projects',
  PAYMENT: 'payments',
};

export const BULK_TYPES_ERROR = {
  GENERAL: 'File not valid',
  SUB_CONTRACTOR: 'File not valid for company import ',
  PROJECT: 'File not valid for project import',
  PAYMENT: 'File not valid for payment import',
};

export const PAYMENT_INSTRUCTION_STATUS_VALUE = {
  for_approval: 'for_approval',
  for_final_approval: 'for_final_approval',
  issued: 'issued',
  declined: 'declined',
  lw_pending: 'lw_pending',
  payment_scheduled: 'payment_scheduled',
  signing_in_progress: 'signing_in_progress',
  paid: 'paid',
  djcPaid: 'djc_paid',
  reversed: 'reversed',
  djcReversed: 'djc_reversed',
  djcPaymentScheduled: 'djc_payment_scheduled',
  djcLwPending: 'djc_lw_pending',
};

export const DJC_PAYMENT_INSTRUCTION_STATUS_VALUE = {
  djc_for_approval: 'djc_for_approval',
  djc_for_final_approval: 'djc_for_final_approval',
  djc_issued: 'djc_issued',
  djc_lw_pending: 'djc_lw_pending',
  djc_sc_confirmation: 'djc_sc_confirmation',
  djc_signing_in_progress: 'djc_signing_in_progress',
  djc_adjustment_requested: 'djc_adjustment_requested',
};

export const PAYMENT_INSTRUCTION_STATUS = {
  for_approval: 'For approval',
  for_final_approval: 'For final approval',
  issued: 'Issued',
  declined: 'Declined',
  lw_pending: 'LW pending',
  payment_scheduled: 'Payment scheduled',
  signing_in_progress: 'Signing in progress',
  paid: 'Paid',
  reversed: 'Reversed',
  // for DJC
  djc: 'DJC',
  sc_confirmation: 'SC Confirmation',
};

export const COLOR_BY_PAYMENT_INSTRUCTION_STATUS = {
  for_approval: '#eab71d',
  for_final_approval: '#EE60E8',
  issued: '#b8b3b3',
  declined: '#d85140',
  lw_pending: '#ffb348',
  payment_scheduled: '#58b078',
  signing_in_progress: '#51DDD5',
  paid: '#5383ec',
  reversed: '#FF7468',
  adjustment_requested: '#ffd967',
  sc_confirmation: '#B327A5',
};

export const XPAY_INVITED_MAP_SUBS_STATUS = {
  new: 'new',
  invited: 'invited',
  active: 'active',
  password_changed: 'password_changed',
  about_company: 'about_company',
  pending: 'pending',
  off_platform: 'off_platform',
};

export const XPAY_INVITED_SUBS_STATUS = {
  new: 'New',
  invited: 'Invited',
  active: 'Active',
  op_account_not_enabled: 'Op. Acct. not enabled',
  missing_in_GBA: 'Not declared in GBA',
  missing_in_SRA: 'Not declared in SRA',
  missing_in_SFA: 'Not declared in SFA',
  about_company: 'About Company',
  pending: 'Pending',
  password_changed: 'Password changed',
  off_platform: 'Off Platform',
};

export const XPAY_PLUS_SUBCONTRACTOR_STATUS_VALUES = {
  not_offered: 'not_offered',
  offered: 'offered',
  enroll: 'enrolled',
  signing_in_progress: 'signing_in_progress',
  resume: 'resume',
  pause: 'paused',
  not_action: 'not_action',
};

export const XPAY_PLUS_SUBCONTRACTOR_STATUS = {
  not_offered: 'Not offered',
  offered: 'Offered',
  enrolled: 'Enrolled',
  signing_in_progress: 'Signing in progress',
  resume: 'Resume',
  paused: 'Paused',
  not_action: '',
};

export const XPAY_PLUS_SUBCONTRACTOR_FILTER_STATUS_DASHBOARD = [
  { value: 'not_offered', name: 'Not offered' },
  { value: 'offered', name: 'Offered' },
  { value: 'enrolled', name: 'Enrolled' },
  { value: 'signing_in_progress', name: 'Signing in progress' },
  { value: 'paused', name: 'Paused' },
];

export const XPAY_PLUS_SUBCONTRACTOR_FILTER_STATUS = [
  { value: 'not_offered', name: 'Not offered' },
  { value: 'offered', name: 'Offered' },
  { value: 'enrolled', name: 'Enrolled' },
  { value: 'signing_in_progress', name: 'Signing in progress' },
  { value: 'resume', name: 'Resume' },
  { value: 'paused', name: 'Pause' },
];

export const XPAY_INVITED_SUBCONTRACTORS_FILTER_STATUS = [
  { name: 'New', value: 'new' },
  { name: 'Invited', value: 'invited' },
  { name: 'Active', value: 'active' },
  { name: 'About company', value: 'about_company' },
  { name: 'Password changed', value: 'password_changed' },
  { name: 'Pending', value: 'pending' },
  { name: 'Off platform', value: 'off_platform' },
];

export const PAYMENT_INSTRUCTION_FILTER_STATUS = [
  { value: 'for_approval', name: 'For approval' },
  { value: 'for_final_approval', name: 'For final approval' },
  { value: 'issued', name: 'Issued' },
  { value: 'declined', name: 'Declined' },
  { value: 'lw_pending', name: 'LW pending' },
  { value: 'payment_scheduled', name: 'Payment scheduled' },
  { value: 'signing_in_progress', name: 'Signing in progress' },
  { value: 'paid', name: 'Paid' },
  { value: 'reversed', name: 'Reversed' },
  { value: 'not_paid', name: 'Not paid' },
];

export const DJC_PAYMENT_INSTRUCTION_FILTER_STATUS = [
  { value: 'djc_for_approval', name: 'For approval', color: '#eab71d' },
  { value: 'djc_for_final_approval', name: 'For final approval', color: '#EE60E8' },
  { value: 'djc_sc_confirmation', name: 'SC Confirmation', color: '#B327A5' },
  { value: 'djc_issued', name: 'Issued', color: '#b8b3b3' },
  { value: 'djc_declined', name: 'Declined', color: '#d85140' },
  { value: 'djc_lw_pending', name: 'LW pending', color: '#ffb348' },
  { value: 'djc_adjustment_requested', name: 'Adjustment requested', color: '#9B6700' },
  { value: 'djc_payment_scheduled', name: 'Payment scheduled', color: '#58b078' },
  { value: 'djc_signing_in_progress', name: 'Signing in progress', color: '#51DDD5' },
  { value: 'djc_paid', name: 'Paid', color: '#5383ec' },
  { value: 'djc_reversed', name: 'Reversed', color: '#FF7468' },
  { value: 'djc_not_paid', name: 'Not paid', color: '#dc0000' },
];

export const PAYMENT_INSTRUCTION_FILTER_PAYMENT_METHOD = [
  { value: 'ach', name: 'ACH' },
  { value: 'check', name: 'Paper check' },
];

export const LOAN_FILTER_STATUS = [
  { name: 'App In Progress', value: 'APP_IN_PROGRESS' },
  { name: 'App In Review', value: 'APP_IN_REVIEW' },
  { name: 'Closed', value: 'CLOSED' },
  { name: 'Quote Generated', value: 'QUOTE_GENERATED' },
  { name: 'Quote Requested', value: 'QUOTE_REQUESTED' },
  { name: 'Servicing', value: 'SERVICING' },
  { name: 'Signing Requested', value: 'SIGNING_REQUESTED' },
];

export const TRANSACTION_TYPES = {
  INTRABANK: 'intrabank',
  PUSH: 'push',
  PULL: 'pull',
};

export const REINVITE_TYPES = {
  REINVITE_BY_XPAY_GC: 'reinvite_by_xpay_gc',
  REINVITE_BY_XPAY_GC_WITH_TEMPLATE: 'reinvite_by_xpay_gc_with_template',
  REINVITE_COMPANY: 'reinvite_company',
  REINVITE_SUPPLIER: 'reinvite_supplier',
};

export const AVAILABLE_PAYMENT_METHODS = {
  ACH: 'ach',
  CHECK: 'check',
};

export const paymentMethods = [
  { value: 'ach', label: 'ACH' },
  { value: 'check', label: 'Paper check' },
];

export const SFA_TRANSACTIONS = {
  FROM_SRA: 'from_rsa',
  FROM_SBA: 'from_sba',
};

export const STRUXTION_TRANSACTIONS = {
  PULL_FROM_GOA: 'PULL_FROM_GOA',
};

export const COLLECTED_STATUS = {
  CANCELED: 'canceled',
  DECLINED: 'declined',
  PENDING: 'pending',
  SETTLED: 'settled',
};

export const COLLECTED_STATUS_COLOR = {
  canceled: '#ea3323',
  declined: '#ea3323',
  pending: '#e5b36d',
  settled: '#198754',
  completed: '#198754',
};

export const AVAILABLE_BANK_CONNECTION = {
  PAPER_CHECK: 'paper_check',
  AUTOMATIC: 'automatic',
  MANUAL: 'manual',
};

export const XPAY_COMPANY_KB_LINK = 'https://struxtion.com/company-knowledge-base/';
export const XPAY_GC_KB_LINK = 'https://struxtion.com/knowledge-base/';

export const XPAY_PAYMENTS_ACTIONS = {
  EDIT: 'edit',
  REVERSE: 'reversed',
  DECLINE: 'declined',
  PAID: 'paid',
  DJC_REVERSE: 'djc_reversed',
  DJC_DECLINE: 'djc_declined',
  LW_NOT_REQUIRED: 'lw_not_required',
  LW_REMINDER: 'lw_reminder',
  RESUBMIT: 'resubmit',
  VIEW_LIEN_WAIVER: 'view-lien-waiver',
  DOWNLOAD_LIEN_WAIVER: 'download-lien-waiver',
};

export const XPAY_PAYMENTS_ACTIONS_LABEL = {
  reversed: 'reverse',
  declined: 'decline',
  resubmit: 'resubmit',
};

export const SOLID_PAGINATION_LIMIT_INIT = 25;
export const SOLID_PAGINATION_LIMITS = [25, 50, 100];

export const ERP_STATUS_COLOR = {
  Initialized: 'inherit',
  Posted: 'action',
  Synced: 'primary',
  Pending: 'secondary',
};

export const SUPPLIER_ROLES = {
  mainContact: 'main_contact',
  lwSigner: 'lien_waiver_signer',
};

export const DJC_VIEW_STATUS = {
  PENDING: 'pending',
  COMPLETE: 'complete',
  ADJUSTMENTS: 'adjustment',
  EDIT: 'edit',
};

export const LienWaiverStatusAliases = {
  no: 'N/A',
  partial: 'Partial',
  final: 'Final',
  not_required: 'Not Required',
};

export const MakePaymentsStatusEnum = {
  NOT_STARTED: 'not_started',
  NOT_STARTED_HIDDEN: 'not_started_hidden',
  REQUESTED: 'requested',
  ENABLED: 'enabled',
};

export const UserSettings = {
  PAYMENT_CUT_OFF_TIME: 'PAYMENT_CUT_OFF_TIME',
  PAYOR_PAYEE_ADVERTISING_POPUP: 'PAYOR_PAYEE_ADVERTISING_POPUP',
};
