import React, { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  makeStyles,
  Modal,
  Paper,
  useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Alert } from '@material-ui/lab';
import { BasicIcons } from '../icons';
import { palette } from '../../theme';
import payorPayeeClockItem from '../../assets/icons/payor-payee-clock.svg';
import payorPayeeCashItem from '../../assets/icons/payor-payee-cash.svg';
import payorPayeeChartItem from '../../assets/icons/payor-payee-chart.svg';
import payorPayeeHandHeartItem from '../../assets/icons/payor-payee-hand-heart.svg';
import useXPayPayorPayee from '../../api/useXPayPayorPayee';
import SuccessMessage from '../SuccessMessage';

const useStyles = makeStyles(() => ({
  paper: {
    position: 'absolute',
    maxHeight: '90vh',
    maxWidth: '900px',
    overflowY: 'auto',
    overflowX: 'hidden',
    margin: '1em',
    padding: '1em',
  },
  centerTheme: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mainContent: {
    display: 'grid',
    gridTemplateColumns: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
  },
  descriptionContent: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1em',
  },
  mainIcon: {
    height: 'auto',
    width: '100%',
    backgroundColor: 'white',
  },
  makePaymentIcon: {
    height: 'auto',
    width: '100%',
    backgroundColor: 'white',
    borderRadius: '20px',
  },
  title: {
    textAlign: 'center',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'normal',
  },
  subtitle: {
    textAlign: 'center',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
  },
  subtitle2: {
    color: '#4C4C4C',
    textAlign: 'center',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'normal',
    padding: '1em',
  },
  subtitle3: {
    color: '#4C4C4C',
    textAlign: 'center',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 'normal',
    padding: '1em',
  },
  subtitle4: {
    textAlign: 'center',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 'normal',
    padding: '1em',
    border: '2px solid #27B373',
    borderRadius: '8px',
    maxWidth: '450px',
  },
  subtitle5: {
    color: '#3A3A3A',
    textAlign: 'center',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
  },
  descriptionItem: {
    display: 'grid',
    height: '100px',
    gridTemplateColumns: 'auto 1fr',
    gridTemplateRows: 'repeat(2, 1fr)',
    alignItems: 'start',
    justifyContent: 'center',
    columnGap: '20px',
    padding: '20px',
  },
  descriptionImage: {
    alignSelf: 'start',
    gridArea: '1 / 1 / 3 / 2',
  },
  descriptionTitle: {
    color: '#4C4C4C',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'normal',
    textAlign: 'left',
    gridArea: '1 / 2 / 2 / 3',
  },
  descriptionText: {
    color: '#4C4C4C',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
    textAlign: 'left',
    gridArea: '2 / 2 / 3 / 3',
  },
}));

export default function PayorPayeeSettingModal({
  open, handleClose, isPayorRequested, isHidden, companyId,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.up('md'));
  const [isDontShowAgainChecked, setIsDontShowAgainChecked] = useState(isHidden);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [responseMessage, setResponseMessage] = React.useState('');

  const {
    loading, error, setShowAgain, sendRequestMakePayments,
  } = useXPayPayorPayee();

  const handleDontShowAgain = () => {
    setShowAgain({ companyId, isShowMakePayorModal: !isDontShowAgainChecked }, () => {
      setIsDontShowAgainChecked(!isDontShowAgainChecked);
      setResponseMessage(!isDontShowAgainChecked ? 'You will not see this dialog automatically again.' : 'This dialog will not appear automatically again. For more information, please visit the \'Make Payments\' option in the sidebar menu.');
      setOpenDialog(true);
    });
  };

  const sendRequest = () => {
    sendRequestMakePayments({ companyId }, () => {
      setResponseMessage('The Request was successfully sent.');
      setOpenDialog(true);
    });
  };

  const reloadAfterPost = () => {
    window.location.reload();
  };

  return (
    <Modal
      className={classes.centerTheme}
      open={open}
      onClose={handleClose}
      aria-labelledby="payee-make-payments-setting"
    >
      <Paper square elevation={24} className={classes.paper}>
        <Box width={!isMobile ? '90%' : '100%'} padding={!isMobile ? 1 : 2} style={{ borderBottom: `1px solid ${palette.midGray}` }}>
          <Box style={{ padding: '1em' }} className={classes.mainContent}>
            <BasicIcons.PayorPayeeMain className={classes.mainIcon} white />
            <span className={classes.title}>New Feature!</span>
            <span className={classes.subtitle}>Expand Your Business with Struxtion</span>
          </Box>
          <Box className={classes.mainContent}>
            <BasicIcons.PayorPayeeMakePayments className={classes.makePaymentIcon} white borderRadius="20px" />
          </Box>
        </Box>

        <Box className={classes.mainContent}>
          <span className={classes.subtitle2}>Empower your business by managing both incoming and outgoing payments seamlessly.</span>
        </Box>

        <Box className={classes.descriptionContent}>
          <Box className={classes.descriptionItem} style={{ borderBottom: `1px solid ${palette.midGray}`, borderRight: `1px solid ${palette.midGray}` }}>
            <img className={classes.descriptionImage} width={50} src={payorPayeeClockItem} alt="this is a green clock vector" loading="lazy" />
            <span className={classes.descriptionTitle}>Consolidated payments</span>
            <span className={classes.descriptionText}>Manage both incoming and outgoing payments in one place, saving you time and effort.</span>
          </Box>

          <Box className={classes.descriptionItem} style={{ borderBottom: `1px solid ${palette.midGray}` }}>
            <img className={classes.descriptionImage} width={50} src={payorPayeeCashItem} alt="this is a green cash vector" loading="lazy" />
            <span className={classes.descriptionTitle}>Improved cash flow</span>
            <span className={classes.descriptionText}>Optimize your payment cycles and gain greater control over your finances.</span>
          </Box>

          <Box className={classes.descriptionItem} style={{ borderRight: `1px solid ${palette.midGray}` }}>
            <img className={classes.descriptionImage} width={50} src={payorPayeeChartItem} alt="this is a green chart vector" loading="lazy" />
            <span className={classes.descriptionTitle}>Scalability</span>
            <span className={classes.descriptionText}>Easily accommodate growth as your business expands.</span>
          </Box>

          <Box className={classes.descriptionItem}>
            <img className={classes.descriptionImage} width={50} src={payorPayeeHandHeartItem} alt="this is a green hand with a heart vector" loading="lazy" />
            <span className={classes.descriptionTitle}>Enhanced supplier relationships</span>
            <span className={classes.descriptionText}>Strengthen your partnerships by offering convenient payment options.</span>
          </Box>
        </Box>

        <Box className={classes.mainContent} style={{ display: isPayorRequested ? 'grid' : 'none' }}>
          <span className={classes.subtitle4}>One of our Struxtion executives will contact you as soon as possible.</span>
        </Box>

        <Box className={classes.mainContent} style={{ display: isPayorRequested ? 'none' : 'grid' }}>
          <span className={classes.subtitle3}>Ready to take your business to the next level?</span>
        </Box>

        <Box className={classes.mainContent} style={{ display: isPayorRequested ? 'none' : 'grid' }}>
          <Button disableElevation variant="contained" color="primary" onClick={() => sendRequest()} size="large" disabled={loading} style={{ fontWeight: 700 }}>SEND REQUEST</Button>
          <Box padding="5px">
            <FormControlLabel
              control={(
                <Checkbox
                  size="small"
                  className={classes.subtitle5}
                  checked={isDontShowAgainChecked}
                  onChange={handleDontShowAgain}
                  inputProps={{ 'aria-label': 'Don\'t show again' }}
                  disabled={loading}
                />
              )}
              label="Don't show again"
            />
          </Box>
        </Box>

        <Box mt={5}>
          { !loading && error && <Alert severity="error">{error}</Alert> }
        </Box>

        <SuccessMessage open={openDialog} setOpen={setOpenDialog} title="Success!" content={responseMessage} onSuccessFunction={() => reloadAfterPost()} />
      </Paper>
    </Modal>
  );
}

PayorPayeeSettingModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  isPayorRequested: PropTypes.bool.isRequired,
  isHidden: PropTypes.bool.isRequired,
  companyId: PropTypes.string.isRequired,
};
