import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import * as icons from '../../assets/icons';

const defaultStyling = {
  width: 30,
  height: 30,
  margin: '0 auto',
};

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    position: 'relative',
  },
});

export const IconWrapper = ({ children, className }) => {
  const classes = useStyles();
  return (
    <div className={`${classes.root} ${className || null}`} style={className ? {} : defaultStyling}>
      { React.cloneElement(children, { className: classes.icon }) }
    </div>
  );
};

export const IconSelector = ({
  name,
  width,
  gradient,
  white,
  borderRadius,
}) => {
  const style = { width, left: 1, borderRadius };
  const alt = `${name} icon`;
  let type = 'black';
  if (gradient) type = 'grad';
  if (white) type = 'white';

  return <img src={icons[name][type].default} alt={alt} style={style} />; // eslint-disable-line
};

const iconComponents = {};

Object.keys(icons).forEach((key) => {
  const { width } = icons[key];
  iconComponents[key] = (props) => (
    <IconWrapper {...props}>
      <IconSelector {...props} name={key} width={width || '75%'} />
    </IconWrapper>
  );
});

IconWrapper.defaultProps = {
  className: undefined,
};

IconWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

IconSelector.defaultProps = {
  white: false,
  gradient: false,
  width: '75%',
  borderRadius: '0',
};

IconSelector.propTypes = {
  name: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  white: PropTypes.bool,
  gradient: PropTypes.bool,
  borderRadius: PropTypes.string,
};

export default iconComponents;
