import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

function SuccessMessage({
  open, setOpen, title, content, onSuccessFunction, hiddenActions,
}) {
  const handleClose = () => {
    onSuccessFunction();
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      {
        !hiddenActions && (
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            OK
          </Button>
        </DialogActions>
        )
      }
    </Dialog>
  );
}

SuccessMessage.defaultProps = {
  open: true,
  setOpen: () => {},
  title: '',
  content: '',
  onSuccessFunction: () => {},
  hiddenActions: false,
};
SuccessMessage.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  title: PropTypes.string,
  content: PropTypes.string,
  onSuccessFunction: PropTypes.func,
  hiddenActions: PropTypes.bool,
};

export default SuccessMessage;
