/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Drawer as MUIDrawer,
  Hidden,
  List,
  makeStyles,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { drawerWidth, MakePaymentsStatusEnum, UserSettings } from '../../constants';
import DrawerLink from './DrawerLink';
import logo from '../../logo-x.svg';
import PayorPayeeSettingModal from '../PayorPayee/PayorPayeeSettingModal';
import payorPayeeMakePaymentsItem from '../../assets/icons/payor-payee-make-payments.svg';

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    boxShadow: theme.shadows[0],
  },
  logoButton: {
    display: 'flex',
    width: '100%',
    minHeight: '38px',
    marginBottom: '1em',
  },
  logo: {
    width: '40px',
    height: 'auto',
    padding: '5px 0',
  },
  payorPayeeMakePayments: {
    marginTop: '20px',
    cursor: 'pointer',
    padding: '2px',
  },
  blinkImage: {
    mozAnimation: 'blink normal 2s infinite ease-in-out', /* Firefox */
    webkitAnimation: 'blink normal 2s infinite ease-in-out', /* Webkit */
    msAnimation: 'blink normal 2s infinite ease-in-out', /* IE */
    animation: 'blink normal 2s infinite ease-in-out', /* Opera and prob css3 final iteration */
  },
}));

export const DrawerLinks = ({ links, onLinkClick }) => (
  <List>
    {
      links.map(({
        label,
        icon,
        to,
        index,
        rolePermission,
      }) => (
        <DrawerLink
          rolePermission={rolePermission}
          key={label}
          label={label}
          icon={icon}
          to={to}
          onClick={onLinkClick}
          index={index}
        />
      ))
    }
  </List>
);

const Drawer = ({
  toggleDrawer,
  drawerOpen,
  links,
  defaultPath,
  user: {
    companyId,
    companyName,
    makePaymentsStatusEnum,
    isPayor,
    isPayee,
    settings,
  },
}) => {
  const location = useLocation();
  const classes = useStyles();
  const [isOpenPayorPayeeSettingModal, setIsOpenPayorPayeeSettingModal] = useState(false);
  const [isPayorRequestedByUser, setIsPayorRequestedByUser] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);

  const handleOpenMakePaymentsModal = (isOpen) => {
    setIsOpenPayorPayeeSettingModal(isOpen);
  };

  useEffect(() => {
    const isEnabledParam = settings.find((setting) => setting.settingName === UserSettings.PAYOR_PAYEE_ADVERTISING_POPUP).settingValue === '1';
    if (!isEnabledParam) {
      setIsOpenPayorPayeeSettingModal(false);
      return;
    }

    setIsEnabled(isEnabledParam);
    const isDashboardCLocation = location.pathname.includes('dashboard');
    const isMakePaymentModalNotStarted = isDashboardCLocation && makePaymentsStatusEnum === MakePaymentsStatusEnum.NOT_STARTED;
    const isMakePaymentModalEnabled = makePaymentsStatusEnum === MakePaymentsStatusEnum.ENABLED;
    const isMakePaymentModalRequested = makePaymentsStatusEnum === MakePaymentsStatusEnum.REQUESTED;
    const isMakePaymentModalHidden = makePaymentsStatusEnum === MakePaymentsStatusEnum.NOT_STARTED_HIDDEN;
    const isOpen = !isPayor && isMakePaymentModalNotStarted && !isMakePaymentModalHidden && !isMakePaymentModalEnabled && !isMakePaymentModalRequested;
    setIsPayorRequestedByUser(isMakePaymentModalRequested);
    setIsOpenPayorPayeeSettingModal(isOpen);
  }, [makePaymentsStatusEnum, isPayor, location, settings]);

  return (
    <nav className={classes.drawer}>
      <Hidden mdUp implementation="css">
        <MUIDrawer
          variant="temporary"
          open={drawerOpen}
          onClose={toggleDrawer}
          classes={{ paper: classes.drawerPaper }}
        >
          <Box textAlign="center">
            <h4>{companyName?.toUpperCase()}</h4>
          </Box>
          <DrawerLinks links={links} onLinkClick={toggleDrawer} />
        </MUIDrawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <MUIDrawer
          variant="permanent"
          open
          classes={{ paper: classes.drawerPaper }}
        >
          <Link to={defaultPath}>
            <Button className={classes.logoButton}>
              <img className={classes.logo} src={logo} alt="Struxtion logo, navigate to home" />
            </Button>
          </Link>
          <DrawerLinks links={links} />
          <Box
            className={classes.payorPayeeMakePayments}
            onClick={() => handleOpenMakePaymentsModal(true)}
            hidden={!isEnabled || (isPayor || (isPayee && makePaymentsStatusEnum === MakePaymentsStatusEnum.ENABLED))}
          >
            <img className={classes.blinkImage} width="100%" src={payorPayeeMakePaymentsItem} alt="this is a banner image" loading="lazy" />
          </Box>
        </MUIDrawer>
      </Hidden>
      <PayorPayeeSettingModal
        open={isOpenPayorPayeeSettingModal}
        handleClose={() => handleOpenMakePaymentsModal(false)}
        isPayorRequested={isPayorRequestedByUser}
        isHidden={makePaymentsStatusEnum === MakePaymentsStatusEnum.NOT_STARTED_HIDDEN}
        companyId={companyId}
      />
    </nav>
  );
};

DrawerLinks.defaultProps = {
  links: [],
  onLinkClick: () => { },
};

DrawerLinks.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    icon: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.object,
    ]),
    to: PropTypes.string,
  })),
  onLinkClick: PropTypes.func,
};

Drawer.defaultProps = {
  links: [],
};

Drawer.propTypes = {
  toggleDrawer: PropTypes.func.isRequired,
  drawerOpen: PropTypes.bool.isRequired,
  links: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    icon: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.object,
    ]),
    to: PropTypes.string,
  })),
  defaultPath: PropTypes.string.isRequired,
  user: PropTypes.shape({
    username: PropTypes.string,
    companyId: PropTypes.string,
    companyName: PropTypes.string,
    makePaymentsStatusEnum: PropTypes.string,
    isPayor: PropTypes.bool,
    isPayee: PropTypes.bool,
    settings: PropTypes.arrayOf(PropTypes.shape({
      settingName: PropTypes.string,
      settingValue: PropTypes.string,
    })),
  }).isRequired,
};

export default Drawer;
